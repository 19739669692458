import { directive } from '@coders-tm/vue-number-format'

export default {
  data() {
    return {
      number: {
        decimal: '.',
        separator: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        mix: 0,
        max: 999_999_999,
        minimumFractionDigits: 2,
      },
    }
  },
  directives: {
    number: directive,
  },
}
